export function isApiPresent() {
    var windowKeys = Object.keys(window);
    if (windowKeys.includes("ipcRenderer"))
        return true;
    return false;
}

export function connectToElectron() {
    if (isApiPresent())
        window.ipcRenderer.send("loginPageConnected");
}

export function loginCloseClicked() {
    window.ipcRenderer.send('loginCloseClicked');
}

export function startDeviceDetection(DeviceDetectionArea) {
    window.ipcRenderer.invoke('startDeviceDetection', JSON.stringify(DeviceDetectionArea)).then((result) => {
        console.log(result);
    });
}

export function closeApplication() {
    window.ipcRenderer.send('closeAction');
}

export function setupGotDeviceInfo(callback) {
    if (isApiPresent()) {
        window.ipcRenderer.on('deviceInformation', (event,data)=>{
            callback(data);
        });
    }
}

export function firmwareDownloaded(data) {
    window.ipcRenderer.send("firmwareDownloaded",data);
}

export function setupConfirmDownload(callback) {
    if (isApiPresent()) {
        window.ipcRenderer.on('confirmDownload', (event)=>{
            callback();
        });
    }
}