import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setDeviceTypesReloadAction } from "../features/deviceTypesSlice";
import HexserverApi from '../utils/hexserverApi';

class addDeviceType extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addADeviceType: false,
            deviceTypeName: '',
            deviceTypeBootloaderFwVer: '',
            isNameValid: false,
            isVersionValid: false,
            submitting: false
        };
        this.version2string = (version) => `V${version.major}.${version.minor}.${version.build}`;
    }

    componentDidMount() {
        this.props.setDeviceTypesReloadAction(true);
    }

    deviceTypeNameValidTest(value) {
        var valid = true;
        if (value.length === 0)
            valid = false;
        var versionRegEx = /V\d+(\.\d+)*$/g;
        var regExRes = versionRegEx.exec(value);
        console.log(regExRes);
        if (!regExRes)
            valid = false;
        this.setState({
            isNameValid: valid,
            deviceTypeName: value
        });
    }
    
    deviceBootVersionValidTest(value) {
        var valid = true;
        var verArray = value.split('.');
        if (!verArray)
            valid = false;
        if (valid && Array.isArray(verArray) && verArray.length !== 3)
            valid = false;
        if (valid)
            verArray.map((num) => {
                var value = parseInt(num);
                if (isNaN(value) || value < 0 || value > 255)
                    valid = false;
                return true;
            });
        this.setState({
            deviceTypeBootloaderFwVer: value,
            isVersionValid: valid
        });
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({submitting: true});
        var verArr = this.state.deviceTypeBootloaderFwVer.split('.');
        var version = {
            major: verArr[0],
            minor: verArr[1],
            build: verArr[2]
        };
        HexserverApi.addDeviceType({
            deviceTypeName: this.state.deviceTypeName, 
            bootloaderSoftwareVersion: version
        },(err,res)=>{
            if (err)
                return console.log(err);
            console.log(res);
            this.props.setDeviceTypesReloadAction(true);
            this.setState({submitting: false});
        });
    }

    onDelete(e, device2Delete) {
        e.preventDefault();
        this.setState({submitting: true});
        console.log('Devie 2 delete:', device2Delete);
        HexserverApi.deleteDeviceType(device2Delete, (err,res)=>{
            if (err)
                return console.log(err);
            console.log(res);
            this.props.setDeviceTypesReloadAction(true);
            this.setState({submitting: false});
        });
    }

    render() {
        return (
            <>
                <h1>Device Types</h1>
                { (this.props.reload || this.props.deviceTypes == null || this.props.deviceTypes.length === 0) ? (
                    <Container className="d-flex justify-content-center">
                        Loading Device Types <Spinner animation="border" role="status" className='mx-sm-2'></Spinner>
                    </Container>
                ) : (

                    <Row>
                        <Col>
                            <ListGroup>
                                {this.props.deviceTypes.map((deviceType, idx)=>{ 
                                    return (
                                        <ListGroup.Item
                                            key={`devType_${idx}`}
                                        >
                                            <Row>
                                                <Col>
                                                    <Row>{deviceType.deviceType}</Row>
                                                    <Row>
                                                        <Col>Bootloader</Col>
                                                        <Col>{this.version2string(deviceType.bootloaderSoftwareVersion)}</Col>
                                                    </Row>
                                                </Col>
                                                <Col xs='2'>
                                                    {(this.props.deviceTypesWFws.includes(deviceType.deviceType)) ? null : (
                                                        <Button
                                                            variant="danger"
                                                            onClick={(e)=>{this.onDelete(e, {
                                                                ...deviceType
                                                            });}}
                                                        >-{this.state.submitting ? (<Spinner animation="border" role="status" className='mx-sm-2'></Spinner>): null}</Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    );
                                })}
                                <ListGroup.Item
                                    onClick={()=>{
                                        this.setState({addADeviceType: true});
                                    }}
                                >
                                    + Add a device type
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        {this.state.addADeviceType ? (
                            <Col>
                                <Form>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Label>Device Type Name</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            placeholder="STIMPODV31.0"
                                            value={this.state.deviceTypeName}
                                            onChange={(e)=>{
                                                var value = e.target.value;
                                                value = value.toUpperCase();
                                                value = value.replace(' ', '');
                                                this.deviceTypeNameValidTest(value);
                                            }}
                                            isInvalid={!this.state.isNameValid}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Device Type Name is invalid. There cannot be any spaces in the name. Must end with a number.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Label>Bootloader firmware version number</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            placeholder="10.4.102"
                                            value={this.state.deviceTypeBootloaderFwVer}
                                            onChange={(e)=>{
                                                var value = e.target.value;
                                                this.deviceBootVersionValidTest(value);
                                            }}
                                            isInvalid={!this.state.isVersionValid}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Version Number is invalid. Format is: Number.Number.Number where number is 0 {"<"}= Number {"<"}= 255
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Row>
                                        <Col className="d-flex justify-content-center">
                                            <Button 
                                                variant="danger" 
                                                type="submit"
                                                onClick={()=>{
                                                    this.setState({addADeviceType:false});
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Col>
                                        <Col className="d-flex justify-content-center">
                                            <Button 
                                                variant="primary" 
                                                type="submit"
                                                disabled={!(this.state.isNameValid && this.state.isVersionValid)}
                                                onClick={(e)=>{this.onSubmit(e);}}
                                            >
                                                Submit{this.state.submitting ? (<Spinner animation="border" role="status" className='mx-sm-2'></Spinner>): null}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        ) : null}
                    </Row>
                )}
            </>
        );
    }

}

const AddDeviceType = connect((state)=>{
    return {
        reload: state.deviceTypes.reload,
        deviceTypes: state.deviceTypes.deviceTypes,
        deviceTypesWFws: Object.keys(state.user.setup.firmwares)
    };
},{
    setDeviceTypesReloadAction
})(withRouter(addDeviceType));

export default AddDeviceType;