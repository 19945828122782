import React from 'react';
import env from "react-dotenv";
import { MemoryRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';

import Login from './Barrier/Login/Login';
import Register from './Barrier/Register/Register';
import ForgotPassword from './Barrier/ForgotPassword/ForgotPassword';
import TestPage from './TestPage/TestPage';
import HexserverApi from './utils/hexserverApi.js';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';
import { setUserAction, setSetupAction } from './features/userSlice';

class App extends React.Component {

  render() {
    return (
      <>
        <div>Host name: {env.HOSTNAME}</div>
        <Router>
          <NestedApp/>
        </Router>
      </>
    );
  }
}

class nestedApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      connectionEstablished: "Establishing connection"
    };
    HexserverApi.setHostName(env.HOSTNAME);
  }

  componentDidMount() {
    console.log("App mounted");
    this.testConnection();
    this.testConnectionInterval = setInterval(this.testConnection.bind(this),10000);
    HexserverApi.userCheck((err,res)=>{
      if (err)
      return console.log(err);
      console.log(res);
      if (res.res) {
        var theUser = res.user;
        HexserverApi.userSettings((err,res)=>{
          if (err)
            return console.log(err);
          this.props.setUserAction(theUser);
          console.log(res.payload);
          this.props.setSetupAction(res.payload);
          this.props.history.push("/test");
        });
      }
      else if (!res.res) {
        this.props.history.push("/");
      }
    });
  }

  componentWillUnmount() {
    clearInterval(this.testConnectionInterval);
  }

  testConnection() {
    console.log("Testing connection");
    HexserverApi.SmallPing((err,res)=>{
      if (err) {
        this.setState({connectionEstablished: "Failed to establish connection to Xavant server",loading: false});
        return;
      }
      this.setState({connectionEstablished:true, loading: false});
    });
  }

  render() {
    return (
      <>
        {this.state.connectionEstablished === true 
        ?
          <Switch>
            <Route path="/register">
              <Register />
            </Route>
            <Route path="/test">
              <TestPage />
            </Route>
            <Route path="/forgot_password">
              <ForgotPassword />
            </Route>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
        :
        <Col className='inner'>
          <Row>
            <Col>
              <div className='text-center'>{this.state.connectionEstablished}</div>
            </Col>
              {this.state.loading ?
                <Col>
                  <Spinner animation="border" role="status" className='mx-sm-2'></Spinner>
                </Col> :
                null
              }
          </Row>
        </Col>
        }
      </>
    );
  }
}

const NestedApp = connect(null,{setUserAction, setSetupAction})(withRouter(nestedApp));

export default App;
