import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/userSlice';
import firmwareReducer from './features/firmwareSlice';
import blacklistedFirmwareReducer from './features/blacklistedFirmwareSlice';
import comprehensiveFirmwareReducer from './features/comprehensiveFirmware';
import deviceTypesReducer from './features/deviceTypesSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    firmware: firmwareReducer,
    blacklisted: blacklistedFirmwareReducer,
    comprehensiveFirmware: comprehensiveFirmwareReducer,
    deviceTypes: deviceTypesReducer
  },
});