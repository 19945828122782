import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { connect } from 'react-redux';
import HexserverApi from '../utils/hexserverApi';
import { withRouter } from 'react-router';
import { setUserAction, setSetupAction } from '../features/userSlice';
import Settings from './Settings';
import FirmwareSelection from './FirmwareSelection';
import { closeApplication, isApiPresent, loginCloseClicked } from '../utils/electronApi';

function mapStateToProps(state) {
    return {
        user: {...state.user},
        reload: state.user.setup.reload,
        firmwareReload: state.firmware.reload,
    };
}

class TestPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            showSettings: false,
            userSettingsLoaded: false
        };
    }

    componentDidMount() {
        HexserverApi.userCheck((err,res)=>{
            if (err)
                return console.log(err);
            console.log("userCheck:",res);
            if (res.res) {
              var theUser = res.user;
              console.log("Getting user settings");
              HexserverApi.userSettings((err,res)=>{
                if (err)
                  console.log(err);
                console.log("User Settings:",res);
                this.props.setUserAction(theUser);
                this.props.setSetupAction(res.payload);
                this.setState({userSettingsLoaded:true});
              });
            }
            else if (!res.res) {
              this.props.history.push("/");
            }
        });
    }

    handleLogout() {
        console.log("Logout");
        HexserverApi.userLogout((err,res)=>{
            if (err)
                return console.log(err);
            console.log(res);
            this.props.setUserAction({name:"",surname:"",email:""});
            this.props.history.push("/");
        });
    }

    toggleSettings() {
        this.setState((state,props)=>({
            showSettings: !state.showSettings
        }));
    }

    render() {
        return (
            <>
                <Navbar bg="dark" variant="dark">
                    <Container>
                        <Navbar.Brand href="#home">Xavant Hexserver</Navbar.Brand>
                        <Nav className="me-auto">
                            <Navbar.Text>
                                Signed in as: <a href="#login">{this.props.user.name} {this.props.user.surname}</a>
                            </Navbar.Text>
                            { this.props.user.setup.isAdmin ?
                                <Nav.Link onClick={()=>this.toggleSettings()}>Settings</Nav.Link>
                            : null }
                            <Button 
                                className='mx-sm-2' 
                                variant="outline-success" 
                                onClick={()=> this.handleLogout()}
                                disabled={(this.state.showSettings && this.props.reload) || this.props.firmwareReload}>
                                    Logout
                            </Button>
                            {isApiPresent() ? 
                                <>
                                    <Button 
                                        className='mx-sm-2' 
                                        variant="outline-success" 
                                        onClick={() => loginCloseClicked()}
                                        disabled={(this.state.showSettings && this.props.reload) || this.props.firmwareReload}>
                                            Close Login
                                    </Button>
                                    <Button 
                                        className='mx-sm-2' 
                                        variant="outline-success" 
                                        onClick={() => closeApplication()}
                                        disabled={(this.state.showSettings && this.props.reload) || this.props.firmwareReload}>
                                            Close Application
                                    </Button>
                                </>
                            : null}
                        </Nav>
                    </Container>
                </Navbar>
                {this.state.userSettingsLoaded 
                ? 
                    <>
                        {this.state.showSettings 
                        ? 
                            <Settings/> 
                        :
                            <FirmwareSelection/>
                        }
                    </>
                :
                    <Container className="d-flex justify-content-center">
                        Loading user settings <Spinner animation="border" role="status" className='mx-sm-2'></Spinner>
                    </Container>
                }
            </>
        );
    }
}

export default connect(mapStateToProps,{setUserAction,setSetupAction})(withRouter(TestPage));