import { createSlice } from '@reduxjs/toolkit';
import env from 'react-dotenv';
import fetchApi from '../utils/fetchAL';

export const blacklistFirmwareSlice = createSlice({
  name: 'blacklistedFirmware',
  initialState: {
    firmwares: [],
    reload: false
  },
  reducers: {
    setBlacklistedFirmwares: (state, action) => {
      state.firmwares = action.payload;
    },
    setReload: (state, action) => {
      state.reload = action.payload;
    }
  },
})

export const { setBlacklistedFirmwares, setReload } = blacklistFirmwareSlice.actions;

export default blacklistFirmwareSlice.reducer;

export const setFirmwaresAction = (firmwaresDetails) => (dispatch) => {
  dispatch(setBlacklistedFirmwares(firmwaresDetails));
}

export const setReloadBlacklistedAction = (reload) => {
  return  (dispatch) => {
    if (reload) {
      loadFirmwareData(dispatch);
    };
    dispatch(setReload(reload));
  };
};

export const deleteBlacklistedAction = (recordId) => {
  return  (dispatch) => {
    deleteABlacklistedItem(dispatch,recordId);
  };
};

export const addBlacklistedAction = (record) => {
  return  (dispatch) => {
    addABlacklistedItem(dispatch,record);
  };
};

function deleteABlacklistedItem(dispatch,recordId) {
  dispatch(setReload(true));
  fetchApi.fetchAL(`${env.HOSTNAME}/device/delete_blacklisted`,fetchApi.METHODS.POST,{_id:recordId},(err,res)=>{
    if (err) {
      dispatch(setReload(false));
      return console.log(err);
    }
    console.log(res);
    loadFirmwareData(dispatch);
  });
}

function loadFirmwareData(dispatch) {
  console.log("Loading blacklisted firmwares");
  fetchApi.fetchAL(`${env.HOSTNAME}/device/blacklist`,fetchApi.METHODS.GET,null,(err,res)=>{
    if (err)
      return console.log(err);
    console.log(res);
    dispatch(setBlacklistedFirmwares(res.res));
    dispatch(setReload(false));
  });
}

function addABlacklistedItem(dispatch,record) {
  dispatch(setReload(true));
  fetchApi.fetchAL(`${env.HOSTNAME}/device/add_blacklisted`,fetchApi.METHODS.POST,record,(err,res)=>{
    if (err) {
      dispatch(setReload(false));
      return console.log(err);
    }
    console.log(res);
    loadFirmwareData(dispatch);
  });
}