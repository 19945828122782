const METHODS = {
    POST: 'POST',
    GET: 'GET'
};

function fetchAL(path,method,jsonInput,callback) {
    var options = {
        method: method,
        credentials: 'include',
        redirect: 'follow'
    };
    if (jsonInput !== null) {
        var isFormData = jsonInput instanceof FormData;
        var body = jsonInput;
        if (!isFormData) {
            options.headers = {
                'Content-Type': 'application/json'
            };
            body = JSON.stringify(jsonInput);
        }
        options.body = body;
    }
    fetch(path, options)
        .then(response => response.json())
        .then(data => {
            callback(null,data);
        })
        .catch((error) => {
            console.log('Error:', error);
            callback("FETCH_FAIL");
        });
}

module.exports = {
    fetchAL,
    METHODS
}