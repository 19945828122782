import React from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';

import './Register.css';

import {
  Link
} from "react-router-dom";

import HexserverApi from '../../utils/hexserverApi.js';

import { withRouter } from 'react-router';

class Register extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      alertMessage: {msg:"", variant: "primary"},
      redirect: ""
    }
  }

  setAlertMessage(type,message) {
    console.log(type,message);
    this.setState({alertMessage:{msg:message,variant:type}});
    setTimeout(()=>{
      this.setState({alertMessage:{msg:"",variant:""}});
    },2000);
  }

  handleSubmit(e) {
    e.preventDefault();
    console.log('You clicked submit.');
    if (e.target['email'].value.length === 0)
      return this.setAlertMessage("danger","Email cannot be nothing");
    if (e.target['name'].value.length === 0)
      return this.setAlertMessage("danger","Name cannot be nothing");
    if (e.target['surname'].value.length === 0)
      return this.setAlertMessage("danger","Surname cannot be nothing");
    if (e.target['password1'].value.length === 0)
      return this.setAlertMessage("danger","Password cannot be nothing");
    var input = {
      username: e.target['email'].value,
      name: e.target['name'].value,
      surname: e.target['surname'].value,
      password1: e.target['password1'].value,
      password2: e.target['password2'].value
    };
    console.log(input);
    var bcrypt = require('bcryptjs');
    // var salt = bcrypt.genSaltSync(10);
    var hash1 = bcrypt.hashSync(input['password1'], "$2a$10$rFMe8ZsjRLKW1zf93JzF.O");
    console.log(hash1);
    if (bcrypt.compareSync(input['password2'], hash1)) {
      delete input['password1'];
      delete input['password2'];
      input['password'] = hash1;
      console.log("Hashes match");
      HexserverApi.userRegister(input,(err,res)=>{
        console.log(err,res);
        if (err)
          return this.setAlertMessage("danger",err);
        console.log(res);
        if (res.err)
          return this.setAlertMessage("danger",res.msg);
        if (!res.res)
          return this.setAlertMessage("danger",res.msg);
        if (res.res) {
          this.setState({redirect:"User registered, check email for next steps."});
          setTimeout(()=>{
            this.props.history.push("/login");
          },3000);
        }
      });
    }
    else {
      console.log("Hashes mismatch");
      return this.setAlertMessage("danger","Passwords do not match");
    }
  }

  render() {
    return (
      <Container>
        <Row className="outer">
          <Col className="inner">
            <Form onSubmit={(e) => this.handleSubmit(e)}>
              <h1 className="h3 mb-3 fw-normal">Register</h1>
              <Form.Group className="form-floating">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" className="form-control" id="email" placeholder="name@example.com" />
              </Form.Group>
              <Form.Group className="form-floating">
                <Form.Label>Name</Form.Label>
                <Form.Control type="name" className="form-control" id="name" placeholder="John" />
              </Form.Group>
              <Form.Group className="form-floating">
                <Form.Label>Surname</Form.Label>
                <Form.Control type="surname" className="form-control" id="surname" placeholder="Doe" />
              </Form.Group>
              <Form.Group className="form-floating">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" className="form-control" id="password1" placeholder="Password" />
              </Form.Group>
              <Form.Group className="form-floating">
                <Form.Label>Retype Password</Form.Label>
                <Form.Control type="password" className="form-control" id="password2" placeholder="Password" />
              </Form.Group>
              {this.state.alertMessage.msg !== ""
                ?
                <Alert variant={this.state.alertMessage.variant}>
                  {this.state.alertMessage.msg}
                </Alert>
                :
                null
              }
              <Button className="w-100 btn btn-lg btn-primary" type="submit">Register</Button>
              <Container>
                Have a user account?{' '}<Link to="/">Login here</Link>
              </Container>
              <Container>
                Forgotten Password?{' '}<Link to="/forgot_password">Click here</Link>
              </Container>
            </Form>
          </Col>
        </Row>
        {this.state.redirect !== ""?
        <ModalMessage message={this.state.redirect}/>:null}
      </Container>
    );
  }

}

function ModalMessage({message}) {

  return (
    <>

      <Modal
        show={true}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          {message}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default withRouter(Register);
