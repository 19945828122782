import { createSlice } from '@reduxjs/toolkit';
import env from 'react-dotenv';
import fetchApi from '../utils/fetchAL';

export const blacklistFirmwareSlice = createSlice({
  name: 'comprehensiveFirmware',
  initialState: {
    firmwares: [],
    reload: false
  },
  reducers: {
    setComprehensiveFirmwares: (state, action) => {
      state.firmwares = action.payload;
    },
    setComprehensiveFirmwaresReload: (state, action) => {
      state.reload = action.payload;
    }
  },
})

export const { setComprehensiveFirmwares, setComprehensiveFirmwaresReload } = blacklistFirmwareSlice.actions;

export default blacklistFirmwareSlice.reducer;

export const setComprehensiveFirmwaresAction = (firmwaresDetails) => (dispatch) => {
  dispatch(setComprehensiveFirmwares(firmwaresDetails));
}

export const setComprehensiveFirmwaresReloadAction = (reload) => {
  return  (dispatch) => {
    if (reload) {
      loadFirmwareData(dispatch);
    };
    dispatch(setComprehensiveFirmwaresReload(reload));
  };
};

function loadFirmwareData(dispatch) {
  console.log("Loading comprehensive firmware details");
  fetchApi.fetchAL(`${env.HOSTNAME}/device/get_comprehensive_firmwareDetails`,fetchApi.METHODS.GET,null,(err,res)=>{
    if (err)
      return console.log(err);
    console.log(res);
    dispatch(setComprehensiveFirmwares(res.res));
    dispatch(setComprehensiveFirmwaresReload(false));
  });
}

export const deepDeleteFirmwaresAction = (fw) => {
  return  (dispatch) => {
    dispatch(setComprehensiveFirmwaresReload(true));
    fetchApi.fetchAL(`${env.HOSTNAME}/device/deep_delete_firmware`,fetchApi.METHODS.POST,fw,(err,res)=>{
      if (err) {
        dispatch(setComprehensiveFirmwaresReload(false));
        return console.log(err);
      }
      console.log(res);
      loadFirmwareData(dispatch);
    });
  };
};