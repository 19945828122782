import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";

import './FirmwareSelection.css';

import { setFirmwaresAction, setReloadAction, setLatestAction, setSelectedAction } from '../features/firmwareSlice';
import { isApiPresent, startDeviceDetection, setupGotDeviceInfo, connectToElectron, firmwareDownloaded, setupConfirmDownload } from '../utils/electronApi';
import HexserverApi from '../utils/hexserverApi';

class firmwareSelection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchingForDevice: false,
            deviceInformation: {}
        };
        connectToElectron();
        setupGotDeviceInfo(this.setDeviceInformation.bind(this));
        setupConfirmDownload(this.downloadFirmwareForDevice.bind(this));
    }

    setDeviceInformation(deviceInformation) {
        console.log("Download firmware requested for the following:");
        console.log(deviceInformation);
        this.setState({deviceInformation: deviceInformation});
    }

    compareSoftwareVersions(version1,version2) {
        var comp = {
            major: version1.major === version2.major,
            minor: version1.minor === version2.minor,
            build: version1.build === version2.build
        }
        comp.res = (comp.major && comp.minor && comp.build) ? true : false;
        return comp;
    }
    
    downloadFirmwareForDevice() {
        console.log("Download firmware requested for the following:");
        // var deviceType = this.checkDeviceType(this.state.deviceInformation);
        var dataFromServer = {};
        console.log(this.state.deviceInformation);
        HexserverApi.downloadDeviceFirmware({deviceInformation:this.state.deviceInformation, selected: this.props.selected},(err,res)=>{
            if (err)
                return console.log(err);
            console.log(res);
            if (res.err)
                return;
            var resFilt = res.filter((item)=> Object.keys(item).includes('deviceType'));
            console.log(resFilt);
            var deviceType = resFilt[0].deviceType;
            dataFromServer.DeviceFirmware = res;
            HexserverApi.DeviceFlash({deviceType: deviceType, version: this.state.deviceInformation.softwareVersion},(err,res)=>{
                if (err) {
                    return console.log(err);
                }
                dataFromServer.DeviceFlash = res;
                firmwareDownloaded({dfs: dataFromServer, latestSoftware: this.props.selected[deviceType]});
            });
        });
    }

    componentDidMount() {
        console.log(this.props);
        this.props.setReloadAction(true, this.props.links, this.props.latestLinks);
    }

    version2versionString(version) {
        return `${version.major}.${version.minor}.${version.build}`;
    }

    onVersionChange(device, value) {
        this.setState({[`edit_${device}`]: value});
    }

    checkSelected(device) {
        var stateKey = `edit_${device}`;
        var stateKeys = Object.keys(this.state);
        if (stateKeys.includes(stateKey) && this.state[stateKey] !== this.version2versionString(this.props.selected[device])) {
            return false;
        }
        return true;
    }

    onSubmit(e, device) {
        e.preventDefault();
        var versionArr = this.state[`edit_${device}`].split(".");
        var version = {
            major: parseInt(versionArr[0]),
            minor: parseInt(versionArr[1]),
            build: parseInt(versionArr[2])
        };
        this.props.setSelectedAction({deviceType: device, selected: version});
    }

    renderOptions() {
        var keys = Object.keys(this.props.firmwares);
        var latestKeys = Object.keys(this.props.latest);
        keys = keys.filter((key) => latestKeys.includes(key));
        keys = keys.sort();
        return (
            <>
                {keys.map((device)=>{
                    var latestVersion = this.props.latest[device];
                    var deviceVersions = [...this.props.firmwares[device]];
                    var versionsLeft = 
                        (deviceVersions.length > 0) 
                        ? 
                            deviceVersions.filter((version) => {return this.version2versionString(version.version) !== this.version2versionString(latestVersion)}) 
                        : 
                            [];
                    return (
                        <Form key={device} onSubmit={(e) => this.onSubmit(e, device)}>
                            <Form.Group as={Row} controlId="formPlaintextPassword">
                                <Form.Label column sm="2"><strong>{device}</strong></Form.Label>
                                <Form.Label column sm="2">Latest:</Form.Label>
                                <Col sm="2">
                                    <Form.Control type="text" placeholder={this.version2versionString(latestVersion)} readOnly/>
                                </Col>
                                <Form.Label column sm="2">Selected:</Form.Label>
                                <Col sm="2">
                                    <Form.Control as="select" custom onChange={(e) => this.onVersionChange(device,e.target.value)}>
                                        <option value={this.version2versionString(latestVersion)}>{this.version2versionString(latestVersion)}</option>
                                        {versionsLeft.map((version) => {
                                            return (
                                                <option 
                                                    key={`key_${this.version2versionString(version.version)}`} 
                                                    value={this.version2versionString(version.version)}>
                                                        {this.version2versionString(version.version)}
                                                </option>
                                            );
                                        })}
                                    </Form.Control>
                                </Col>
                                <Button type="submit" disabled={this.checkSelected(device)}>Change</Button>
                            </Form.Group>
                        </Form>
                    );
                })}
            </>
        );
    }

    getBoundingClientRect(element) {
        var rect = element.getBoundingClientRect();
        return {
          top: rect.top,
          right: rect.right,
          bottom: rect.bottom,
          left: rect.left,
          width: rect.width,
          height: rect.height,
          x: rect.x,
          y: rect.y
        };
      }

    onStartDeviceDetectionClick() {
        this.setState({searchingForDevice:true});
        startDeviceDetection(this.getBoundingClientRect(document.getElementById("DeviceDetection")));
    }

    render() {
        return (
            <>
            {this.props.reload 
                ?
                    <Container className="d-flex justify-content-center">
                        Loading your firmwares <Spinner animation="border" role="status" className='mx-sm-2'></Spinner>
                    </Container>
                :
                <div className="theGrid">
                    <Container id="FirmwareSelection" className="d-flex flex-column justify-content-center my-sm-2">
                        <Container>
                            {this.renderOptions()}
                        </Container>
                        <Button 
                            disabled={!isApiPresent() || this.state.searchingForDevice}
                            onClick={() => this.onStartDeviceDetectionClick()}>
                                Start device detection
                        </Button>
                        {!isApiPresent()? <h1>Not running in app</h1>:null}
                    </Container>
                    <div id="DeviceDetection">
                    </div>
                </div>
            }
            </>
        );
    }
}

function mapPathsInState2props(state) {
    return {
        links: state.user.setup.availiblePaths.firmwares,
        latestLinks: state.user.setup.availiblePaths.latest,
        reload: state.firmware.reload,
        firmwares: state.firmware.firmwares,
        latest: state.firmware.latest,
        selected: state.firmware.selectedFirmwares
    };
}

export default connect(mapPathsInState2props, {setFirmwaresAction, setReloadAction, setLatestAction, setSelectedAction})(firmwareSelection);
