import React from "react";
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setSetupAction, setFirmwaresAction, setReloadAction, setLatestAction } from '../features/userSlice';
import { setReloadBlacklistedAction, deleteBlacklistedAction, addBlacklistedAction } from "../features/blacklistedFirmwareSlice";
import { setComprehensiveFirmwaresReloadAction, deepDeleteFirmwaresAction } from "../features/comprehensiveFirmware";
import HexserverApi from '../utils/hexserverApi';
import AddDeviceType from "./AddDeviceType";

class settings extends React.Component {

    componentDidMount() {
        this.props.setReloadAction(true, this.props.links, this.props.latestLinks);
    }

    render() {
        return (
            <>
                {this.props.reload 
                ?
                    <Container className="d-flex justify-content-center">
                        Loading Admin Settings <Spinner animation="border" role="status" className='mx-sm-2'></Spinner>
                    </Container>
                :
                    <Container className="my-sm-2">
                        <Row>
                            <Col>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Row>
                                        <Col sm={3}>
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first">Roles</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second">User Roles</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="third">Upload firmware</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fourth">Latest firmware</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fifth">Blacklisted firmware</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="six">Delete Firmware</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="seven">Device Types</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col sm={9}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <Roles/>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <UserRoles/>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third">
                                                    <UploadFirmware/>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="fourth">
                                                    <LatestFirmware/>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="fifth">
                                                    <BlacklistFirmware/>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="six">
                                                    <DeleteFirmware/>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="seven">
                                                    <AddDeviceType/>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </Col>
                        </Row>
                    </Container>
                }
            </>
        );
    }
}

function mapState2SettingsProps(state) {
    return {
        links: state.user.setup.links2load.firmwares,
        latestLinks: state.user.setup.links2load.latest,
        reload: state.user.setup.reload
    };
}

const Settings = connect(mapState2SettingsProps,{ setFirmwaresAction, setReloadAction, setLatestAction })(withRouter(settings));

export default Settings;

class roles extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentRole: -2,
            editName: "",
            editFirmwares: [],
            proceed: false,
            firmWares: this.props.firmwares,
            dataLoaded: true,
            possibleFirmwareAdd: "",
            submitting: false
        }
        this.checkInterval = null;
        var firmwareList = [];
        Object.keys(this.props.firmwares).map((deviceType)=>{
            var dtFirmwares = this.props.firmwares[deviceType];
            var dtFirmwaresMap = dtFirmwares.map((fw)=>{
                return {
                    ...fw,
                    deviceType: deviceType
                };
            });
            firmwareList.push(...dtFirmwaresMap);
        });
        console.log("*",firmwareList);
        this.firmwareObj = {};
        firmwareList.map((fw)=>{
            this.firmwareObj[fw._id] = fw;
        });
        console.log(this.firmwareObj);
        this.version2string = (version) => `V${version.major}.${version.minor}.${version.build}`;
    }

    componentDidMount() {
        this.checkInterval = setInterval(this.checkIfCanSaveSubmit.bind(this),1000);
    }

    componentWillUnmount() {
        clearInterval(this.checkInterval);
    }

    fwAccess2View(firmwares) {
        console.log("======",firmwares);
        return firmwares.map((fw)=>{
            var label = fw;
            if (fw !== "ALL") {
                console.log(this.props.firmwares);
                label = `${this.firmwareObj[fw].deviceType}/${this.version2string(this.firmwareObj[fw].version)}`;
            }
            return {
                value: fw,
                label: label
            }
        });
    };

    changeCurrentRole(role) {
        var editName = (role < 0) ? "" : this.props.roles[role].name;
        var editFws = (role < 0) ? [] : this.fwAccess2View(this.props.roles[role].firmwareAccess);
        this.setState({currentRole:role, editName: editName, editFirmwares: editFws});
    }

    nameChange(e) {
        this.setState({editName:e.target.value.toUpperCase()});
    }

    checkIfCanSaveSubmit() {
        var proceed = true;
        var editName = true;
        var editFirmwares = true;
        if (this.state.editName.length < 3)
            editName = false;
        if (this.state.currentRole >= 0 && this.state.editName === this.props.roles[this.state.currentRole].name)
            editName = false;
        if (this.state.currentRole >= 0 && JSON.stringify(this.state.editFirmwares) === JSON.stringify(this.fwAccess2View(this.props.roles[this.state.currentRole].firmwareAccess)))
            editFirmwares = false;
        else if (this.state.editFirmwares.length === 0)
            editFirmwares = false;
        var crit = (this.state.currentRole >= 0) ? (!editName && !editFirmwares) : (!editName || !editFirmwares);
        if (crit)
            proceed = false;
        this.setState({proceed:proceed});
    }

    removeAEditFirmware(indexNum) {
        this.setState((state,props)=>{
            var editFws = [...state.editFirmwares];
            editFws.splice(indexNum,1);
            return {
                editFirmwares: editFws
            };
        });
    }

    addAEditFirmware() {
        var firmware = this.state.possibleFirmwareAdd;
        var value = firmware;
        console.log(firmware);
        console.log(this.firmwareObj);
        var label = `${this.firmwareObj[firmware].deviceType}/${this.version2string(this.firmwareObj[firmware].version)}`;
        this.setState((state,props)=>{
            var editFirmwares = (value === "ALL") ? [] : [...state.editFirmwares];
            editFirmwares.push({value:value, label:label});
            return {
                editFirmwares: editFirmwares
            };
        });
    }

    onAPossibleFirmwareChange(firmware) {
        this.setState({possibleFirmwareAdd: firmware});
    }

    onFormSubmit(e) {
        e.preventDefault();
        this.setState({submitting:true});
        var role = {
            name: this.state.editName,
            firmwareAccess: [...this.state.editFirmwares.map((fw) => fw.value)]
        }
        if (this.state.currentRole < 0) {
            HexserverApi.userAddRole(role,(err,res)=>{
                if (err) {
                    return console.log(err);
                }
                this.setState({submitting: false, currentRole: -2});
                HexserverApi.userSettings((err,res)=>{
                    if (err)
                    console.log(err);
                    console.log("User Settings:",res);
                    this.props.setSetupAction(res.payload);
                });
            });
        }
        else {
            role._id = this.props.roles[this.state.currentRole]._id;
            HexserverApi.userEditRole(role,(err,res)=>{
                if (err) {
                    return console.log(err);
                }
                this.setState({submitting: false, currentRole: -2});
                HexserverApi.userSettings((err,res)=>{
                    if (err)
                    console.log(err);
                    console.log("User Settings:",res);
                    this.props.setSetupAction(res.payload);
                });
            });
        }
    }

    onDeleteRole(id) {
        console.log(`Going to delete role with id: ${id}`);
        this.setState({submitting:true});
        HexserverApi.userDeleteRole(id,(err,res)=>{
            this.setState({submitting: false, currentRole: -2});
            HexserverApi.userSettings((err,res)=>{
                if (err)
                console.log(err);
                console.log("User Settings:",res);
                this.props.setSetupAction(res.payload);
            });
        });
    }

    render() {
        return (
            <>
            {this.state.dataLoaded
            ?
                <Container>
                    <Row>
                        <Col sm={6}>
                            <ListGroup defaultActiveKey="#link1">
                                {this.props.roles.map((role,idx)=>{
                                    return (
                                        <ListGroup.Item key={`key_${role.name}`} action href={`#key_${role.name}`} onClick={() => this.changeCurrentRole(idx)}>
                                            {role.name}
                                        </ListGroup.Item>    
                                    );
                                })}
                                <ListGroup.Item action href="#new" onClick={() => this.changeCurrentRole(-1)}>
                                    + New Role
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={6}>
                            {this.state.currentRole !== -2?
                                <Form onSubmit={(e) => this.onFormSubmit(e)}>
                                    <Form.Group className="mb-3" controlId="Name">
                                        <Form.Label>Role Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Role Name" value={this.state.editName} onChange={(e)=>this.nameChange(e)} disabled={this.state.submitting}/>
                                    </Form.Group>
                                    <Form.Group controlId="currentFirmware">
                                        <Form.Label>Linked firmwares:</Form.Label>
                                        <ListGroup>
                                            {/* <ListGroup.Item>Cras justo odio</ListGroup.Item> */}
                                            {this.state.editFirmwares.map((fw,idx)=>{
                                                return (
                                                    <ListGroup.Item key={fw.value}>
                                                        <Container>
                                                            <Row>
                                                                <Col>{fw.label}</Col>
                                                                <Col className="d-flex justify-content-end"><Button variant="danger" size="sm" onClick={() => this.removeAEditFirmware(idx)} disabled={this.state.submitting}>-</Button></Col>
                                                            </Row>
                                                        </Container>
                                                    </ListGroup.Item>
                                                );
                                            })}
                                        </ListGroup>
                                    </Form.Group>
                                    {!this.state.editFirmwares.find((el)=> el.label === "ALL")
                                    ?
                                        <Form.Group controlId="addFirmware">
                                            <Form.Label>Add Firmware</Form.Label>
                                            <Row>
                                                <Col>
                                                    <Form.Control as="select" custom onChange={(e) => this.onAPossibleFirmwareChange(e.target.value)}>
                                                        <option value="" hidden>Select your option</option>
                                                        <option value="ALL">ALL</option>
                                                        {Object.keys(this.state.firmWares).map((group)=>{
                                                            console.log(group);
                                                            return (
                                                                <optgroup label={group} key={`key_${group}`}>
                                                                    {this.state.firmWares[group].map((firmware)=>{
                                                                        var fw = firmware.version;
                                                                        var version = `${fw.major}.${fw.minor}.${fw.build}`;
                                                                        return (
                                                                            <option key={`key_${version}`} value={firmware._id}>{version}</option>
                                                                        );
                                                                    })}
                                                                </optgroup>
                                                            );
                                                        })}
                                                    </Form.Control>
                                                </Col>
                                                <Col>
                                                    <Button variant="success" onClick={() => this.addAEditFirmware()} disabled={this.state.possibleFirmwareAdd === "" || this.state.submitting}>+</Button>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    :
                                        null
                                    }
                                    <Container>
                                        <Row>
                                            <Col>
                                                <Button variant="primary" type="submit" disabled={!this.state.proceed || this.state.submitting}>
                                                    {this.state.currentRole === -1 ? "Submit" : "Save"}
                                                    {this.state.submitting ? <Spinner animation="border" role="status" className='mx-sm-2'></Spinner> : null}
                                                </Button>
                                            </Col>
                                            {this.state.currentRole >= 0
                                            ?
                                                <Col className="d-flex justify-content-end">
                                                    <Button variant="danger" disabled={this.state.submitting} onClick={() => this.onDeleteRole(this.props.roles[this.state.currentRole]._id)}>
                                                        Delete Role
                                                        {this.state.submitting ? <Spinner animation="border" role="status" className='mx-sm-2'></Spinner> : null}
                                                    </Button>
                                                </Col>
                                            : 
                                                null
                                            }
                                        </Row>
                                    </Container>
                                </Form>
                            :null}
                        </Col>
                    </Row>
                </Container>
            :
                <Container>
                    <Row className="justify-content-md-center">
                        <Spinner animation="border" role="status" className='mx-sm-2'></Spinner>
                    </Row>
                </Container>
            }
            </>
        );
    }
}

function mapStateToPropsRolesAndLinks(state) {
    return {
        roles: state.user.setup.roles,
        links: state.user.setup.links2load,
        firmwares: state.user.setup.firmwares
    };
}

const Roles = connect(mapStateToPropsRolesAndLinks, {setSetupAction, setReloadAction})(withRouter(roles));

class userRoles extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            selectedUser: {},
            selectedUserRoles: [],
            editedUserRoles: [],
            remainingUserRoles: [],
            selectedUserRole: {},
            selectedUserRoleIdx: -1,
            submitting: false
        };
        console.log("UserRoles props:",props);
    }

    selectedAUser(idx) {
        console.log(`Selecting user at index: ${idx}`);
        this.setState((state,props)=>{
            var user = props.users[idx];
            var userRoles = props.usersRoles.find((el) => el.user === user._id);
            console.log(userRoles);
            var userRolesNames = [];
            if (userRoles) {
                userRolesNames = userRoles.roles.map((role)=>{
                    var roleName = props.roles.find((fRole) => fRole._id === role);
                    return roleName;
                });
            }
            console.log(userRolesNames);
            var remainingRoles = props.roles.filter((el) => !userRolesNames.includes(el));
            console.log(remainingRoles);
            return {
                selectedUser: {...props.users[idx]},
                selectedUserRoles: [...userRolesNames],
                editedUserRoles: [...userRolesNames],
                remainingUserRoles: [...remainingRoles]
            };
        });
    }

    AddARole() {
        this.setState((state,props)=>{
            var remain = [...state.remainingUserRoles];
            remain.splice(state.selectedUserRoleIdx,1);
            var selected = {...state.selectedUserRole};
            var editedUserRoles = [...state.editedUserRoles];
            editedUserRoles.push(selected);
            return {
                selectedUserRole: {},
                selectedUserRoleIdx: -1,
                editedUserRoles: editedUserRoles,
                remainingUserRoles: remain
            };
        });
    }

    deleteARole(idx) {
        this.setState((state,props) => {
            var removingRole = {...state.editedUserRoles[idx]};
            var newEditedUserRoles = [...state.editedUserRoles];
            newEditedUserRoles.splice(idx,1);
            var newRemaining = [...state.remainingUserRoles];
            newRemaining.push(removingRole);
            return {
                editedUserRoles: newEditedUserRoles,
                remainingUserRoles: newRemaining
            };
        });
    }

    selectedARole(idx) {
        this.setState((state,props)=>{
            return {
                selectedUserRole: state.remainingUserRoles[idx],
                selectedUserRoleIdx: idx
            };
        });
    }

    canSubmit() {
        var selectedUserRoles = this.state.selectedUserRoles.map((r) => r._id);
        selectedUserRoles.sort();
        var editedUserRoles = this.state.editedUserRoles.map((r) => r._id);
        editedUserRoles.sort();
        return JSON.stringify(selectedUserRoles) === JSON.stringify(editedUserRoles);
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({submitting: true});
        var payload = {
            user: this.state.selectedUser._id,
            roles: this.state.editedUserRoles.map((r) => r._id)
        }
        console.log(payload);
        HexserverApi.userAddRoles2User(payload,(err,res)=>{
            if (err) {
                return console.log(err);
            }
            console.log("roles update:",res);
            if (res.res) {
                HexserverApi.userSettings((err,res)=>{
                    if (err)
                    console.log(err);
                    console.log("User Settings:",res);
                    this.props.setSetupAction(res.payload);
                    this.setState({
                        selectedUser: {},
                        selectedUserRoles: [],
                        editedUserRoles: [],
                        remainingUserRoles: [],
                        selectedUserRole: {},
                        selectedUserRoleIdx: -1,
                        submitting: false
                    });
                });
            }
        });
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col sm={6}>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                {(!Object.keys(this.state.selectedUser).includes("username")) ? "Select a User": this.state.selectedUser.username}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {this.props.users.map((user,idx)=>{
                                    return (
                                        <Dropdown.Item key={idx} onClick={() => this.selectedAUser(idx)}>
                                            <Row>{user.name} {user.surname}</Row>
                                            <Row><Col xs={{offset:1}}><em><small>{user.username}</small></em></Col></Row>
                                        </Dropdown.Item>
                                    );
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    {Object.keys(this.state.selectedUser).includes("username")
                    ?
                        <Col sm={6}>
                            <Form onSubmit={(e) => this.onSubmit(e)}>
                                <Form.Group controlId="currentRoles">
                                    <Form.Label>Current Roles:</Form.Label>
                                    <ListGroup>
                                        {this.state.editedUserRoles.map((ur,idx)=>{
                                            return (
                                                <ListGroup.Item key={ur._id}>
                                                    <Container>
                                                        <Row>
                                                            <Col>{ur.name}</Col>
                                                            {ur.name === "ADMIN" && this.props.username === this.state.selectedUser.username ? null : 
                                                                <Col className="d-flex justify-content-end"><Button variant="danger" size="sm" onClick={() => this.deleteARole(idx)} disabled={this.state.submitting}>-</Button></Col>
                                                            }
                                                        </Row>
                                                    </Container>
                                                </ListGroup.Item>
                                            );
                                        })}
                                    </ListGroup>
                                </Form.Group>
                                {this.state.remainingUserRoles.length > 0 ? 
                                    <Form.Group controlId="addRoles">
                                        <Form.Label>Add Roles</Form.Label>
                                        <Row>
                                            <Col>
                                                <Form.Control as="select" custom onChange={(e)=> this.selectedARole(e.target.value)}>
                                                    <option value="" hidden>Select your option</option>
                                                        {this.state.remainingUserRoles.map((rur,idx)=>{
                                                            return (
                                                                <option key={rur._id} value={idx}>{rur.name}</option>
                                                            );
                                                        })}
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                                <Button variant="success" disabled={this.state.selectedUserRoleIdx === -1 || this.state.submitting} onClick={() => this.AddARole()}>+</Button>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                : null}
                                <Button type="submit" disabled={this.canSubmit()}>
                                    Save
                                    {this.state.submitting ?
                                        <Spinner animation="border" role="status" className='mx-sm-2'></Spinner>
                                    :null}
                                </Button>
                            </Form>
                        </Col>
                    :
                        null
                    }
                </Row>
            </Container>
        );
    }
}

function mapStateToPropsRolesUsersAndUsersRoles(state) {
    return {
        roles: state.user.setup.roles,
        users: state.user.setup.users,
        usersRoles: state.user.setup.usersRoles,
        username: state.user.email
    };
}

const UserRoles = connect(mapStateToPropsRolesUsersAndUsersRoles, { setSetupAction })(withRouter(userRoles));

function isVersionValid(versionNum) {
    var valid = true;
    var verSplit = versionNum.split(".");
    if (verSplit.length === 3) {
        for (var i = 0; i < verSplit.length; i++) {
            const val = parseInt(verSplit[i]);
            if (isNaN(val)) {
                valid = false;
            }
            if (val < 0 || val > 255)
                valid = false;
        }
    }
    else
        valid = false;
    return valid;
}

class uploadFirmware extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deviceType: "",
            hexFile: null,
            firmwareFile: null,
            firmwareVersion: "",
            // validation
            firmwareVersionValid: false,
            validNumber: 0,

            submitting: false
        };
    }

    onDeviceTypeChange(deviceType) {
        console.log(`Changing device type to: ${deviceType}`);
        this.setState((state,props) => {
            return {
                deviceType: deviceType,
                validNumber: state.validNumber | 1
            };
        });
    }

    onFileUpload(isHex,file) {
        console.log(file);
        if (isHex) {
            this.setState((state,props) => {
                var validNumber = state.validNumber;
                if (file)
                    validNumber = validNumber | 2;
                else
                    validNumber = validNumber & 0b1101;
                return {
                    hexFile: file,
                    validNumber: validNumber
                };
            });
        }
        else {
            this.setState((state,props) => {
                var validNumber = state.validNumber;
                if (file)
                    validNumber = validNumber | 4;
                else
                    validNumber = validNumber & 0b1011;
                return {
                    firmwareFile: file,
                    validNumber: validNumber
                };
            });
        }
    }

    onVersionChange(versionNum) {
        console.log(`Changing firmware version number to: ${versionNum}`);
        var valid = isVersionValid(versionNum);
        this.setState((state,props)=>{
            var validNumber = state.validNumber
            validNumber = (valid) ? validNumber | 8 : validNumber & 0b0111;
            return {
                firmwareVersion: versionNum, 
                firmwareVersionValid: valid,
                validNumber: validNumber
            };
        });
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({submitting: true});
        let formdata = new FormData();
        formdata.append('flashstruct', this.state.firmwareFile);
        formdata.append('version', this.state.firmwareVersion);
        formdata.append('deviceType', this.state.deviceType);
        formdata.append('hex', this.state.hexFile);
        HexserverApi.firmwareUpload('userDevice',formdata,(err,res)=>{
            console.log(err);
            console.log(res);
            this.setState({submitting: true});
            this.props.setReloadAction(true,this.props.links, this.props.latestLinks);
        });
    }

    render() {
        return (
            <>
                {(this.props.deviceTypeReload || this.props.userSetupReload) ? (
                    <Spinner animation="border" role="status" className='mx-sm-2'></Spinner>
                ) : (
                    <Form onSubmit={(e) => this.onSubmit(e)}>
                        <Form.Group controlId="exampleForm.SelectCustom">
                            <Form.Label>Select Device Type</Form.Label>
                            <Form.Control 
                                as="select" 
                                custom 
                                onChange={(e) => 
                                this.onDeviceTypeChange(e.target.value)} 
                                disabled={this.state.submitting}>
                                    <option value="" hidden>Select your device type</option>
                                    {this.props.deviceTypes.map((devDetails,idx)=>{
                                        return (
                                            <option key={idx} value={devDetails}>{devDetails}</option>
                                        );
                                    })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.SelectCustom">
                            <Form.File 
                                id="custom-file"
                                label="Firmware Hex file"
                                onChange={(e) => this.onFileUpload(true,e.target.files[0])}
                                disabled={this.state.submitting}
                                />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.SelectCustom">
                            <Form.File 
                                id="custom-file"
                                label="Firmware Flash structure"
                                onChange={(e) => this.onFileUpload(false,e.target.files[0])}
                                disabled={this.state.submitting}
                                />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Version number</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="10.4.102" 
                                onChange={(e) => this.onVersionChange(e.target.value)} 
                                isInvalid={!this.state.firmwareVersionValid}
                                disabled={this.state.submitting}
                                />
                            <Form.Control.Feedback type="invalid">
                                Version Number is invalid. Format is: Number.Number.Number where number is 0 {"<"}= Number {"<"}= 255
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={this.state.validNumber !== 15}>
                            Submit
                            {this.state.submitting 
                            ?
                                <Spinner animation="border" role="status" className='mx-sm-2'></Spinner>
                            :
                                null
                            }
                        </Button>
                    </Form>
                )}
            </>
        );
    }

}

function mapState2UploadFirmwareProps(state) {
    return {
        links: state.user.setup.links2load.firmwares,
        latestLinks: state.user.setup.links2load.latest,
        deviceTypes: state.deviceTypes.deviceTypes.map((type) => {
            return type.deviceType;
        }).sort(),
        deviceTypeReload: state.deviceTypes.reload,
        userSetupReload: state.user.setup.reload
    };
}

const UploadFirmware = connect(mapState2UploadFirmwareProps,{setReloadAction})(withRouter(uploadFirmware));

class latestFirmware extends React.Component {

    constructor(props) {
        super(props);
        var devices = {};
        this.props.devices.map((device)=>{
            var stateKeyName = `edit_${device}`;
            devices[stateKeyName] = this.version2string(this.props.latest[device]);
            return {};
        });
        this.state = {
            ...devices,
            submitting: false
        };
    }

    componentDidMount() {
        console.log(this.props);
    }

    version2string(version) {
        if (version)
            return `${version.major}.${version.minor}.${version.build}`;
        return '';
    }

    onVersionChange(device,value) {
        console.log(`Changing ${device} to ${value}`);
        this.setState({[`edit_${device}`]: value});
    }

    onFormSubmit(e,device) {
        e.preventDefault();
        this.setState({submitting:true});
        console.log(`Changing latest version of ${device}`);
        var versionString = this.state[`edit_${device}`];
        var versionDict = {};
        var versionArr = versionString.split('.');
        if (versionArr.length === 3) {
            versionDict = {
                major: parseInt(versionArr[0]),
                minor: parseInt(versionArr[1]),
                build: parseInt(versionArr[2]),
            }
        }
        var sendObj = {...versionDict};
        sendObj.deviceType = device;
        console.log(sendObj);
        HexserverApi.firmwareSetLatest('userDevice',sendObj,(err,res)=>{
            if (err) {
                return console.log(err);
            }
            console.log(res);
            if (res.err) {
                return console.log(res.err);
            }
            if (res.res) {
                console.log("Latest submitted");
                this.setState({submitting: false});
                this.props.setReloadAction(true, this.props.links, this.props.latestLinks);
            }
        });
    }

    render() {
        return (
            <>
            {this.props.reload ? null :
            <>
                {this.props.devices.map((device)=>{
                    return (
                        <Form 
                        onSubmit={(e) => this.onFormSubmit(e,device)}
                        key={`device_${device}`}>
                            <Form.Group as={Row} controlId="formPlaintextPassword">
                                <Form.Label column sm="2">{device}</Form.Label>
                                <Col sm="8">
                                    <Form.Control 
                                        as="select" 
                                        custom
                                        onChange={(e)=> this.onVersionChange(device,e.target.value)}
                                        disabled={this.state.submitting}>
                                            <option 
                                                value={this.version2string(this.props.latest[device])}
                                                key={this.version2string(this.props.latest[device])}>
                                                    {this.version2string(this.props.latest[device])}
                                            </option>
                                            {this.props.theRest[device].map((fw)=>{
                                                return (
                                                    <option 
                                                        key={this.version2string(fw)}
                                                        value={this.version2string(fw)}>
                                                            {this.version2string(fw)}
                                                    </option>
                                                );
                                            })}
                                    </Form.Control>
                                </Col>
                                <Button 
                                    type="submit" 
                                    disabled={
                                        this.version2string(this.props.latest[device]) === this.state[`edit_${device}`] || 
                                        this.state.submitting}>
                                    Submit
                                    {this.state.submitting 
                                    ?
                                        <Spinner animation="border" role="status" className='mx-sm-2'></Spinner>
                                    :
                                        null
                                    }
                                </Button>
                            </Form.Group>
                        </Form>
                    );
                })}
            </>
            }
            </>
                
        );
    }

}

function mapState2LatestFirmwareProps(state) {
    var latest = state.user.setup.latest;
    var firmwares = state.user.setup.firmwares;
    var theRest = {};
    // var devices = Object.keys(firmwares);
    var devices = state.deviceTypes.deviceTypes.map((type)=>{ return type.deviceType; });
    devices = devices.sort();
    if (firmwares){
        for (var i = 0; i < devices.length; i++) {
            var device = devices[i];
            var deviceLatest = latest[device];
            var fwRest = (firmwares[device]) ? firmwares[device].filter((fw) => JSON.stringify(fw.version) !== JSON.stringify(deviceLatest)) : [];
            theRest[device] = fwRest.map((fw) => {return (fw.version) ? fw.version : {};})
        }
    }
    return {
        devices: devices,
        latest: latest,
        theRest: theRest,
        reload: state.user.setup.reload,
        links: state.user.setup.links2load.firmwares,
        latestLinks: state.user.setup.links2load.latest,
    };
}

const LatestFirmware = connect(mapState2LatestFirmwareProps, {setReloadAction})(withRouter(latestFirmware));

class blacklistFirmware extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: "ALL",
            addNew: false,
            showModal: false,
            modalMessage: "",
            modalAcceptClick: ()=>{},
            versionInvalid: true,
            versionInput: "",
            deviceTypeInput: null
        };
    }

    componentDidMount() {
        this.props.setReloadBlacklistedAction(true);
    }
    
    render(){
        return (
            <>
                <h1>Blacklisted firmware</h1>
                {this.props.reload || this.props.deviceTypeReload ? (
                    <>
                        <div>Loading<Spinner animation="border" role="status" className='mx-sm-2'></Spinner></div>
                    </>
                )
                : (
                    <>
                        <Form.Group controlId="filterBlacklistedFirmware">
                            <Form.Label>Filter</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control as="select" custom onChange={(e)=>{
                                        this.setState({filter:e.target.value});
                                    }}>
                                        <option value="ALL">ALL</option>
                                        {this.props.deviceTypes.map((dt,idx)=>{
                                            return (
                                                <option value={dt} key={`dt_${idx}`}>{dt}</option>
                                            );
                                        })}
                                    </Form.Control>
                                </Col>
                            </Row>
                        </Form.Group>
                        <p>Device type filtering: {this.state.filter}</p>
                        <Container>
                            <Row>
                                <Col sm={this.state.addNew ? 6 : 12}>
                                    <ListGroup defaultActiveKey="#link1">
                                        {this.props.blacklistedFirmwares.map((blFw,idx)=>{
                                            return this.state.filter === "ALL" || this.state.filter === blFw.deviceType ? (
                                            <ListGroup.Item 
                                                key={`blFw_${idx}`}>
                                                    <Container>
                                                        <Row className="justify-content-between align-items-center">
                                                            <div>
                                                                {blFw.deviceType}: {blFw.version.major}.{blFw.version.minor}.{blFw.version.build}
                                                            </div>
                                                            <Button 
                                                                variant="danger"
                                                                onClick={()=>{
                                                                    console.log(`Delete record procedure`);
                                                                    this.setState({
                                                                        showModal:true,
                                                                        modalMessage:`Are you sure you want to remove ${blFw.deviceType} with firmware version ${blFw.version.major}.${blFw.version.minor}.${blFw.version.build} from the blacklist?`,
                                                                        modalAcceptClick: () => {
                                                                            console.log(`Deleting ${blFw._id}`);
                                                                            this.props.deleteBlacklistedAction(blFw._id);
                                                                        }
                                                                    });
                                                                }}>-</Button>
                                                        </Row>
                                                    </Container>
                                            </ListGroup.Item>) : null;
                                        })}
                                        <ListGroup.Item action onClick={()=>{
                                            this.setState({addNew:true});
                                        }}>
                                            + New Blacklisted firmware
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                {this.state.addNew ? (
                                    <Col sm={6}>
                                        <Form>
                                            <Form.Group controlId="addFirmware">
                                                <Form.Label>Device Type</Form.Label>
                                                <Row>
                                                    <Col>
                                                        <Form.Control as="select" custom onChange={(e)=>{
                                                            this.setState({deviceTypeInput:e.target.value});
                                                        }}>
                                                            <option value="" hidden>Select your option</option>
                                                            {this.props.deviceTypes.map((dt,idx)=>{
                                                                return (
                                                                    <option value={dt} key={`dt2_${idx}`}>{dt}</option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="Name">
                                                <Form.Label>Firmware version</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="1.1.1" 
                                                    isInvalid={this.state.versionInvalid}
                                                    onChange={(e)=>{
                                                        this.setState({
                                                            versionInvalid:!isVersionValid(e.target.value),
                                                            versionInput:e.target.value
                                                        });
                                                    }}/>
                                                <Form.Control.Feedback type="invalid">
                                                    Version Number is invalid. Format is: Number.Number.Number where number is 0 {"<"}= Number {"<"}= 255
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form>
                                        <Button 
                                            disabled={this.state.versionInvalid || this.state.deviceTypeInput === null}
                                            onClick={()=>{
                                                console.log("Submitting");
                                                var versionArr = this.state.versionInput.split(".");
                                                var record = {
                                                    version: {
                                                        major: versionArr[0],
                                                        minor: versionArr[1],
                                                        build: versionArr[2],
                                                    },
                                                    deviceType: this.state.deviceTypeInput
                                                };
                                                this.props.addBlacklistedAction(record);
                                                this.setState({
                                                    addNew:false,
                                                    deviceTypeInput: null,
                                                    versionInput: "",
                                                    versionInvalid: true
                                                });
                                            }}
                                        >
                                                Submit
                                        </Button>
                                        <Button onClick={()=>{this.setState({addNew:false})}}>Cancel</Button>
                                    </Col>
                                ) : null}
                            </Row>
                        </Container>
                    </>
                )}
                {this.state.showModal ? (
                    <>
                        <Modal show={true}>
                            <Modal.Header>
                                <Modal.Title>Blacklisted entry deletion</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <p>{this.state.modalMessage}</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={()=>{
                                    this.setState({showModal:false});
                                }}>Close</Button>
                                <Button variant="primary" onClick={()=>{
                                    this.setState({showModal:false});
                                    this.state.modalAcceptClick();
                                }}>Confirm</Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                ) : null }
            </>
        );
    }
}

const BlacklistFirmware = connect((state)=>{return {
    reload: state.blacklisted.reload,
    deviceTypeReload: state.deviceTypes.reload,
    blacklistedFirmwares: state.blacklisted.firmwares,
    deviceTypes: state.deviceTypes.deviceTypes.map((type)=>{ return type.deviceType; }).sort()
};}, {setReloadBlacklistedAction, deleteBlacklistedAction, addBlacklistedAction })(withRouter(blacklistFirmware));

class deleteFirmware extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: "ALL",
            showModal: false,
            modalMessage: "",
            modalAcceptClick: ()=>{},
            showLatest: false
        }
    }

    componentDidMount() {
        this.props.setComprehensiveFirmwaresReloadAction(true);
    }

    render() {
        return (
            <>
                <h1>Delete Firmware</h1>
                {this.props.reload 
                ?
                    <Container className="d-flex justify-content-center">
                        Loading Delete Firmware Settings <Spinner animation="border" role="status" className='mx-sm-2'></Spinner>
                    </Container>
                :
                    <>
                        <Form.Group controlId="filterBlacklistedFirmware">
                            <Form.Label>Filter</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control as="select" custom onChange={(e)=>{
                                        this.setState({filter:e.target.value});
                                    }}>
                                        <option value="ALL">ALL</option>
                                        {this.props.deviceTypes.map((dt,idx)=>{
                                            return (
                                                <option value={dt} key={`dt_${idx}`}>{dt}</option>
                                            );
                                        })}
                                    </Form.Control>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group controlId="filterBlacklistedFirmware">
                            <Form.Label>Filter</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Check 
                                        type="checkbox"
                                        id={`default-"checkbox"}`}
                                        label={`Show latest`}
                                        onChange={(e)=>{
                                            this.setState({showLatest:e.target.checked});
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        <div>Filtering for: {this.state.filter}</div>
                        <ListGroup>
                            {this.props.firmwares.map((fw,idx)=>{
                                var showAll = this.state.showLatest;
                                if ((this.state.filter === "ALL" || this.state.filter === fw.deviceType) && (showAll || (!showAll && fw.isLatest === false)))
                                return (
                                    <ListGroup.Item key={`fw_${idx}`}>
                                        <Container>
                                            <Row>
                                                <Col xs={{span:11}}>
                                                    <Container>
                                                        <Row>
                                                            <Col>
                                                                {fw.deviceType}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={{offset:1, span: 3}}>Firmware version:</Col>
                                                            <Col>{`${fw.version.major}.${fw.version.minor}.${fw.version.build}`}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={{offset:1, span: 3}}>Firmware File:</Col>
                                                            <Col>{fw.firmwareFileName} (...{fw.firmwareFileId.slice(-7)})</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={{offset:1, span: 3}}>Flash File:</Col>
                                                            <Col>{fw.flashFileName} (...{fw.flashFileId.slice(-7)})</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={{offset:1, span: 3}}>Latest:</Col>
                                                            <Col>{fw.isLatest ? "YES" : "NO"}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={{offset:1, span: 3}}>Blacklisted:</Col>
                                                            <Col>{fw.isBlacklisted ? "YES" : "NO"}</Col>
                                                        </Row>
                                                        {/* Roles affected start */}
                                                        <Row>
                                                            <Col xs={{offset:1, span: 3}}>Roles affected:</Col>
                                                            {fw.affectedRoles.length === 0 ? 
                                                                <Col>None</Col>
                                                            : null}
                                                        </Row>
                                                        {/* ROLES: */}
                                                        {fw.affectedRoles.length > 0 ? 
                                                            <Row>
                                                                <Col xs={{offset:2}}>
                                                                    <ul>
                                                                        {fw.affectedRoles.map((role,idx)=>{
                                                                            return (
                                                                                <li key={idx}>{role.name}</li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                </Col>
                                                            </Row>
                                                        : null }
                                                        {/* Roles affected end */}
                                                    </Container>
                                                </Col>
                                                <Col>
                                                    <Button 
                                                        variant="danger"
                                                        onClick={()=>{
                                                            var message = `Are your sure you want to delete firmware ${fw.version.major}.${fw.version.minor}.${fw.version.build} for ${fw.deviceType}?\n
                                                            This will include the following:`
                                                            var htmlMessage = (
                                                                <>
                                                                    <p>{message}</p>
                                                                    <ul>
                                                                        {fw.isLatest ? <li>Trying to change the latest firmware<sup>[1]</sup></li> : null}
                                                                        {fw.isBlacklisted ? <li>Removing the firmware from the blacklist</li> : null}
                                                                        {fw.affectedRoles.length > 0 ? (
                                                                            <li>
                                                                                <p>Removing this firmware from the following roles:</p>
                                                                                <ul>
                                                                                    {fw.affectedRoles.map((role,idx)=>{
                                                                                        return (
                                                                                            <li key={idx}>{role.name}</li>
                                                                                        );
                                                                                    })}
                                                                                </ul>
                                                                            </li>
                                                                        ) : null}
                                                                        <li>Deleting firmware file: {fw.firmwareFileName} (...{fw.firmwareFileId.slice(-7)})</li>
                                                                        <li>Deleting flash file: {fw.flashFileName} (...{fw.flashFileId.slice(-7)})</li>
                                                                    </ul>
                                                                    {fw.isLatest ? (
                                                                        <p>[1]<em>Please note that if the latest firmware cannot be changed, this operation will abort. The latest firmware will default to the first available firmware for this device type.</em></p>
                                                                    ) : null}
                                                                </>
                                                            );
                                                            this.setState({
                                                                showModal:true,
                                                                modalMessage: htmlMessage,
                                                                modalAcceptClick: () => {
                                                                    console.log("Going to delete:",fw);
                                                                    this.props.deepDeleteFirmwaresAction(fw);
                                                                }
                                                            });
                                                        }}
                                                    >-</Button>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </ListGroup.Item>
                                );
                                else return (null);
                            })}
                        </ListGroup>
                    </>
                }
                {this.state.showModal ? (
                    <Modal show={true}>
                        <Modal.Header>
                            <Modal.Title>Blacklisted entry deletion</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div>{this.state.modalMessage}</div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=>{
                                this.setState({showModal:false});
                            }}>Close</Button>
                            <Button variant="primary" onClick={()=>{
                                this.setState({showModal:false});
                                this.state.modalAcceptClick();
                            }}>Confirm</Button>
                        </Modal.Footer>
                    </Modal>
                ) : null }
            </>
        );
    }
}

const DeleteFirmware = connect((state)=>{return {
    reload: state.comprehensiveFirmware.reload,
    firmwares: state.comprehensiveFirmware.firmwares,
    deviceTypes: Object.keys(state.user.setup.firmwares)
};}, { setComprehensiveFirmwaresReloadAction, deepDeleteFirmwaresAction })(withRouter(deleteFirmware));