// import React, { useState } from 'react';
import React from 'react';

import { withRouter } from 'react-router';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner'

import {
  Link
} from "react-router-dom";

import './Login.css';

import HexserverApi from '../../utils/hexserverApi.js';
import { connect } from 'react-redux';
import { setUserAction } from '../../features/userSlice';
import { isApiPresent, loginCloseClicked, closeApplication } from '../../utils/electronApi';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      toastMessage: ""
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    console.log('You clicked submit.');
    this.setState({loading:true});
    var input = {
      username: e.target['email'].value,
      password: e.target['password'].value,
    };
    var bcrypt = require('bcryptjs');
    var hash = bcrypt.hashSync(input['password'], "$2a$10$rFMe8ZsjRLKW1zf93JzF.O");
    console.log(hash);
    input['password'] = hash;
    HexserverApi.userLogin(input,(err,res)=>{
      if (err) {
        this.setState({loading:false});
        this.setToastMessage(err);
        return;
      }
      if (res.res)
        this.loggedIn(res.user);
      else {
        this.setState({loading:false});
        this.setToastMessage(res.msg);
      }
    });
  }

  loggedIn(user) {
    console.log("user:",user);
    this.props.setUserAction(user);
    this.props.history.push("/test");
  }

  setToastMessage(msg) {
    this.setState({toastMessage:msg});
    setTimeout(()=>this.setState({toastMessage:""}),2000);
  }

  render() {
    return (
      <>
        <Container>
          <Row className="outer">
            <Col className="inner">
              <Form onSubmit={(e) => this.handleSubmit(e)}>
                <h1 className="h3 mb-3 fw-normal">Please sign in</h1>
                <Form.Group className="form-floating">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" className="form-control" id="email" placeholder="name@example.com" />
                </Form.Group>
                <Form.Group className="form-floating">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" className="form-control" id="password" placeholder="Password" />
                </Form.Group>
                <Button className="w-100 btn btn-lg btn-primary" type="submit">Sign in
                  {this.state.loading ?
                    <Spinner animation="border" role="status" className='mx-sm-2'></Spinner> :
                    null
                  }
                </Button>
                {this.state.toastMessage !== "" ?
                <div className='text-center bg-danger my-sm-2'>
                  {this.state.toastMessage}
                </div>
                :
                null
                }
                <Container>
                  Not a user?{' '}<Link to="/register">Register here</Link>
                </Container>
                <Container>
                  Forgotten Password?{' '}<Link to="/forgot_password">Click here</Link>
                </Container>
              </Form>
            </Col>
          </Row>
        </Container>
        {isApiPresent() 
        ?
          <>
            <Container>
              <button 
                type="button" 
                className="close" 
                aria-label="Close"
                onClick={() => loginCloseClicked()}>
                  <span aria-hidden="true">Close Login &times;</span>
              </button>
            </Container>
            <Container>
              <button 
                type="button" 
                className="close" 
                aria-label="Close"
                onClick={() => closeApplication()}>
                  <span aria-hidden="true">Close Application &times;</span>
              </button>
            </Container>
          </>
        : 
          null 
        }
      </>
    )
  }

}

export default connect(null,{ setUserAction })(withRouter(Login));
