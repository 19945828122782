import { createSlice } from '@reduxjs/toolkit';
import env from 'react-dotenv';
import fetchApi from '../utils/fetchAL';

export const firmwareSlice = createSlice({
  name: 'firmware',
  initialState: {
    firmwares: {},
    latest: {},
    selectedFirmwares: {},
    reload: false
  },
  reducers: {
    setFirmwares: (state, action) => {
      var newFirmwares = {...state.firmwares};
      newFirmwares[action.payload.deviceType] = action.payload.firmwares;
      state.firmwares = newFirmwares;
    },
    setReload: (state, action) => {
      state.reload = action.payload;
    },
    setLatest: (state, action) => {
      var newLatest = {...state.latest};
      newLatest[action.payload.deviceType] = action.payload.latest;
      state.latest = newLatest;
    },
    setSelected: (state, action) => {
      var newSelectedFirmwares = {...state.selectedFirmwares};
      newSelectedFirmwares[action.payload.deviceType] = action.payload.selected;
      state.selectedFirmwares = newSelectedFirmwares;
    },
  },
})

export const { setFirmwares, setReload, setLatest, setSelected } = firmwareSlice.actions;

export default firmwareSlice.reducer;

export const setFirmwaresAction = (firmwaresDetails) => (dispatch) => {
  dispatch(setFirmwares(firmwaresDetails));
}

export const setReloadAction = (reload, links, latestLinks) => {
  return  (dispatch) => {
    if (reload) {
      loadFirmwareData(dispatch,links,latestLinks);
    };
    dispatch(setReload(reload));
  };
};


export const setLatestAction = (latestsDetails) => (dispatch) => {
  dispatch(setLatest(latestsDetails));
}

export const setSelectedAction = (latestsDetails) => (dispatch) => {
  dispatch(setSelected(latestsDetails));
}

function loadFirmwareData(dispatch,links,latestLinks) {
  if (links.length === 0 || latestLinks.length === 0)
    return;
  console.log("Loading firmwares");
  var i = 0;
  const forCbFunc = () => {
      var link = links[i];
      // var body = null;
      // var device = null;
      // if (Array.isArray(links[i])) {
      //     link = links[i][0];
      //     device = links[i][1];
      //     body = {deviceType: device};
      // }
      // else {
      //     device = link.split("/")[1].toUpperCase();
      // }
      // fetchApi.fetchAL(`${env.HOSTNAME}${link}`,fetchApi.METHODS.GET,body,(err,res)=>{
      fetchApi.fetchAL(`${env.HOSTNAME}${link}`,fetchApi.METHODS.GET,null,(err,res)=>{
          // i++;
          if (err || res.err) {
              return console.log(err|res.err);
          }
          // console.log(`Firmware for ${device} loaded`);
          // dispatch(setFirmwares({deviceType: device, firmwares: res.res}));
          var firmwareList = res.res;
          console.log(firmwareList);
          for (var j = 0; j < firmwareList.length; j++) {
            var item = firmwareList[j];
            dispatch(setFirmwares({...item}));
          }
          // if (i < links.length) {
          //     forCbFunc();
          // }
          // else {
          //     // this.props.setReloadAction(false);
          //     i = 0;
              forCbFunc2();
          // }
      });
  };
  forCbFunc();
  const forCbFunc2 = () => {
      var link = latestLinks[i];
      // var body = null;
      // var device = null;
      // if (Array.isArray(latestLinks[i])) {
      //     link = latestLinks[i][0];
      //     device = latestLinks[i][1];
      //     body = {deviceType: device};
      // }
      // else {
      //     device = link.split("/")[1].toUpperCase();
      //     body = {deviceType: device};
      // }
      // fetchApi.fetchAL(`${env.HOSTNAME}${link}`,fetchApi.METHODS.GET,body,(err,res)=>{
      fetchApi.fetchAL(`${env.HOSTNAME}${link}`,fetchApi.METHODS.GET,null,(err,res)=>{
          // i++;
          if (err) {
            return console.log(err);
          }
          var latestList = res.res;
          console.log(latestList);
          var itemList1 = latestList.map((el)=>{return {
            deviceType: el.deviceType,
            latest: el.version
          }});
          var itemList2 = latestList.map((el)=>{return {
            deviceType: el.deviceType,
            selected: el.version
          }});
          for (var j = 0; j < latestList.length; j++) {
            dispatch(setLatest({...itemList1[j]}));
            dispatch(setSelected({...itemList2[j]}));
          }
          // console.log(`Latest for ${device} loaded`);
          // if (i < latestLinks.length) {
          //     forCbFunc2();
          // }
          // else {
          //     console.log("Set reload to false");
              dispatch(setReload(false));
          // }
      });
  };
}