import { createSlice } from '@reduxjs/toolkit';
import env from 'react-dotenv';
import fetchApi from '../utils/fetchAL';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    name: "",
    surname: "",
    email: "",
    setup: {
        isAdmin: false,
        reload: true,
        availiblePaths: {
          firmwares: [],
          latest: []
        }
    }
  },
  reducers: {
    setUser: (state, action) => {
      state.name = action.payload.name;
      state.surname = action.payload.surname;
      state.email = action.payload.email;
    },
    setSetup: (state, action) => {
        state.setup = {...state.setup, ...action.payload};
    },
    setSettingsFirmwares: (state, action) => {
      var newSetup = {...state.setup};
      var newFirmwares = {...newSetup.firmwares};
      newFirmwares[action.payload.deviceType] = action.payload.firmwares;
      newSetup.firmwares = newFirmwares;
      state.setup = newSetup;
    },
    setSettingsReload: (state,action) => {
      state.setup = {...state.setup, reload: action.payload};
    },
    setSettingsLatest: (state, action) => {
      var newSetup = {...state.setup};
      var newLatest = {...newSetup.latest};
      newLatest[action.payload.deviceType] = action.payload.latest;
      newSetup.latest = newLatest;
      state.setup = newSetup;
    },
  },
})

export const { setUser, setSetup, setSettingsFirmwares, setSettingsReload, setSettingsLatest } = userSlice.actions;

export default userSlice.reducer;

export const setUserAction = (user) => (dispatch) => {
    dispatch(setUser(user));
}

export const setSetupAction = (setup) => (dispatch) => {
    dispatch(setSetup(setup));
}

export const setFirmwaresAction = (firmwaresDetails) => (dispatch) => {
  dispatch(setSettingsFirmwares(firmwaresDetails));
}

export const setReloadAction = (reload, links, latestLinks) => {
  return (dispatch) => {
    loadData(dispatch, links, latestLinks);
    dispatch(setSettingsReload(reload));
  };
}

export const setLatestAction = (latestsDetails) => (dispatch) => {
  dispatch(setSettingsLatest(latestsDetails));
}

function loadData(dispatch, links, latestLinks) {
  console.log("Settings Loading firmwares");
  var i = 0;
  const forCbFunc = () => {
      var link = links[i];
      // var body = null;
      // var device = null;
      // if (Array.isArray(links[i])) {
      //     link = links[i][0];
      //     device = links[i][1];
      //     body = {deviceType: device};
      // }
      // else {
      //     device = link.split("/")[1].toUpperCase();
      // }
      // fetchApi.fetchAL(`${env.HOSTNAME}${link}`,fetchApi.METHODS.POST,body,(err,res)=>{
      fetchApi.fetchAL(`${env.HOSTNAME}${link}`,fetchApi.METHODS.GET,null,(err,res)=>{
          // i++;
          if (err || res.err) {
              return console.log(err|res.err);
          }
          console.log(res.res);
          for (var j = 0; j < res.res.length; j++) {
            // dispatch(setSettingsFirmwares({deviceType: device, firmwares: res.res}));
            dispatch(setSettingsFirmwares({...res.res[j]}));
          }
          // if (i < links.length) {
          //     forCbFunc();
          // }
          // else {
          //     // dispatch(// setReload(false));
          //     i = 0;
              forCbFunc2();
          // }
      });
  };
  forCbFunc();
  const forCbFunc2 = () => {
      var link = latestLinks[i];
      // var body = null;
      // var device = null;
      // if (Array.isArray(latestLinks[i])) {
      //     link = latestLinks[i][0];
      //     device = latestLinks[i][1];
      //     body = {deviceType: device};
      // }
      // else {
      //     device = link.split("/")[1].toUpperCase();
      //     body = {deviceType: device};
      // }
      // fetchApi.fetchAL(`${env.HOSTNAME}${link}`,fetchApi.METHODS.POST,body,(err,res)=>{
      fetchApi.fetchAL(`${env.HOSTNAME}${link}`,fetchApi.METHODS.GET,null,(err,res)=>{
        if (err || res.err) {
          return console.log(err|res.err);
        }
        console.log(res.res);
        var mappedLatest = res.res.map((el)=>{return{
          deviceType: el.deviceType,
          latest: el.version
        }});
        for (var j = 0; j < mappedLatest.length; j++) {
          // dispatch(setSettingsLatest({deviceType: device, latest: res.res}));
          dispatch(setSettingsLatest({...mappedLatest[j]}));
        }
          // if (i < latestLinks.length) {
          //     forCbFunc2();
          // }
          // else {
              dispatch(setSettingsReload(false));
          // }
      });
  };
}