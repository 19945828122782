// import React, { useState } from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  Link
} from "react-router-dom";

import './ForgotPassword.css';

const ForgotPassword = () => (
  <Container>
    <Row className="outer">
      <Col className="inner">
        <Form>
          <h1 className="h3 mb-3 fw-normal">Request Password Change</h1>
          <Form.Group className="form-floating">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
          </Form.Group>
          <Button className="w-100 btn btn-lg btn-primary" type="submit">Sign in</Button>
          <Container>
            Not a user?{' '}<Link to="/register">Register here</Link>
          </Container>
          <Container>
            Have a user account?{' '}<Link to="/">Login here</Link>
          </Container>
        </Form>
      </Col>
    </Row>
  </Container>
);

export default ForgotPassword;
