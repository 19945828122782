
import { createSlice } from '@reduxjs/toolkit';
import env from 'react-dotenv';
import fetchApi from '../utils/fetchAL';

export const deviceTypesSlice = createSlice({
  name: 'deviceTypes',
  initialState: {
    deviceTypes: [],
    reload: false
  },
  reducers: {
    setDeviceTypes: (state, action) => {
      state.deviceTypes = action.payload;
    },
    setDeviceTypesReload: (state, action) => {
      state.reload = action.payload;
    }
  },
})

export const { setDeviceTypes, setDeviceTypesReload } = deviceTypesSlice.actions;

export default deviceTypesSlice.reducer;

export const setDeviceTypesAction = (firmwaresDetails) => (dispatch) => {
  dispatch(setDeviceTypes(firmwaresDetails));
}

export const setDeviceTypesReloadAction = (reload) => {
  return  (dispatch) => {
    if (reload) {
      loadDeviceData(dispatch);
    };
    dispatch(setDeviceTypesReload(reload));
  };
};

function loadDeviceData(dispatch) {
  console.log("Loading device types");
  fetchApi.fetchAL(`${env.HOSTNAME}/userDevice/devices`,fetchApi.METHODS.GET,null,(err,res)=>{
    if (err)
      return console.log(err);
    console.log(res);
    dispatch(setDeviceTypes(res.res));
    dispatch(setDeviceTypesReload(false));
  });
}