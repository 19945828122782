require('dotenv').config();
const fetchAL = require('./fetchAL');
// const log = require("electron-log");
var hostName = process.env.HOSTNAME;
var flashFiles = {};
var fs = require("fs");

function DeviceCheck(deviceInfo,callback) {
    console.log(fetchAL);
    fetchAL.fetchAL(`${hostName}/device/check`,fetchAL.METHODS.POST,deviceInfo,(err,res)=>{
        if (err)
            return callback(err);
        callback(null,res);
    });
}

function DeviceFirmware(serialNumberToken,callback) {
    fetchAL.fetchAL(`${hostName}/device/firmware`,fetchAL.METHODS.POST,serialNumberToken,(err,res)=>{
        if (err)
            return callback(err);
        callback(null,res);
    });
}

function DeviceFlash(serialNumberTokenVersion,callback) {
    fetchAL.fetchAL(`${hostName}/device/flash_struct`,fetchAL.METHODS.POST,serialNumberTokenVersion,(err,res)=>{
        if (err)
            return callback(err);
        callback(null,res);
    });
}

function DeviceUpdate(token_sUpdated_sStats,callback) {
    // emg sUpdated must be present
    fetchAL.fetchAL(`${hostName}/device/updated`,fetchAL.METHODS.POST,token_sUpdated_sStats,(err,res)=>{
        if (err)
            return callback(err);
        callback(null,res);
    });
}

function Ping(callback) {
    // emg sUpdated must be present
    callback(null,"Establishing connection with server");
    fetchAL.fetchAL(`${hostName}/test`,fetchAL.METHODS.GET,null,(err,res)=>{
        console.log(err);
        if (err)
            return callback(err);
        var StimpodDefault = {deviceType: "STIMPOD",
            version: {major: 0,minor: 0,build: 0}
        };
        var StimpodNew = {deviceType: "STIMPOD",version: {major: 10,minor: 4,build: 97}};
        var EMG = {deviceType: "EMG",version: {major: 2,minor: 1,build: 61}};
        var fileNum = 0;
        fs.mkdirSync("./tmp");
        fetchAL.fetchAL(`${hostName}/device/flash_struct`,fetchAL.METHODS.POST,StimpodDefault,(err,res)=>{
            if (err)
                return callback(err);
            console.log(res);

            var dataArray = res[0]["flash"]["data"];
            var dataString = "";
            for (var i = 0; i < dataArray.length; i++) {
                dataString += String.fromCharCode(dataArray[i]);
            }
            var filePath = `./tmp/test${fileNum++}`;
            fs.writeFileSync(filePath,dataString);
            flashFiles["STIMPOD"] = [filePath];

            fetchAL.fetchAL(`${hostName}/device/flash_struct`,fetchAL.METHODS.POST,StimpodNew,(err,res)=>{
                if (err)
                    return callback(err);
                var dataArray = res[0]["flash"]["data"];
                var dataString = "";
                for (var i = 0; i < dataArray.length; i++) {
                    dataString += String.fromCharCode(dataArray[i]);
                }
                var filePath = `./tmp/test${fileNum++}`;
                fs.writeFileSync(filePath,dataString);
                flashFiles["STIMPOD"].push(filePath);

                fetchAL.fetchAL(`${hostName}/device/flash_struct`,fetchAL.METHODS.POST,EMG,(err,res)=>{
                    if (err)
                        return callback(err);
                    var dataArray = res[0]["flash"]["data"];
                    var dataString = "";
                    for (var i = 0; i < dataArray.length; i++) {
                        dataString += String.fromCharCode(dataArray[i]);
                    }
                    var filePath = `./tmp/test${fileNum++}`;
                    fs.writeFileSync(filePath,dataString);
                    flashFiles["EMG"] = [filePath];

                    console.log(flashFiles);
                    callback(null,true);
                });
            });
        });
    });
}

function setHostName(hostname) {
    hostName = hostname;
}

function SmallPing(callback) {
    // emg sUpdated must be present
    // callback(null,"Establishing connection with server");
    fetchAL.fetchAL(`${hostName}/test`,fetchAL.METHODS.GET,null,(err,res)=>{
        if (err)
            return callback(err);
        if ('alive' in res && res.alive)
            callback(null,"PING ACK");
    });
}

function userLogin(input, callback) {
    fetchAL.fetchAL(`${hostName}/users/login`,fetchAL.METHODS.POST,input,(err,res)=>{
        if (err)
            return callback(err);
        callback(null,res);
    });
}

function userRegister(input, callback) {
    fetchAL.fetchAL(`${hostName}/users/register`,fetchAL.METHODS.POST,input,(err,res)=>{
        if (err)
            return callback(err);
        callback(null,res);
    });
}

function userCheck(callback) {
    fetchAL.fetchAL(`${hostName}/users`,fetchAL.METHODS.GET,null,(err,res)=>{
        if (err)
            return callback(err);
        callback(null,res);
    });
}

function userLogout(callback) {
    fetchAL.fetchAL(`${hostName}/users/logout`,fetchAL.METHODS.GET,null,(err,res)=>{
        if (err)
            return callback(err);
        callback(null,res);
    });
}

function userSettings(callback) {
    fetchAL.fetchAL(`${hostName}/users/personal_settings`,fetchAL.METHODS.GET,null,(err,res)=>{
        if (err)
            return callback(err);
        callback(null,res);
    });
}

function userAddRole(role,callback) {
    fetchAL.fetchAL(`${hostName}/users/add_role`,fetchAL.METHODS.POST,role,(err,res)=>{
        if (err)
            return callback(err);
        callback(null,res);
    });
}

function userEditRole(role,callback) {
    fetchAL.fetchAL(`${hostName}/users/edit_role`,fetchAL.METHODS.POST,role,(err,res)=>{
        if (err)
            return callback(err);
        callback(null,res);
    });
}

function userDeleteRole(roleId,callback) {
    fetchAL.fetchAL(`${hostName}/users/delete_role`,fetchAL.METHODS.POST,{_id: roleId},(err,res)=>{
        if (err)
            return callback(err);
        callback(null,res);
    });
}

function userAddRoles2User(userAndRoles,callback) {
    fetchAL.fetchAL(`${hostName}/users/add_roles_to_user`,fetchAL.METHODS.POST,userAndRoles,(err,res)=>{
        if (err)
            return callback(err);
        callback(null,res);
    });
}

function firmwareUpload(device, formData ,callback) {
    fetchAL.fetchAL(`${hostName}/${device}/firmware`,fetchAL.METHODS.POST,formData,(err,res)=>{
        if (err)
            return callback(err);
        callback(null,res);
    });
}

function firmwareSetLatest(device, firmware ,callback) {
    fetchAL.fetchAL(`${hostName}/${device}/latest`,fetchAL.METHODS.POST,firmware,(err,res)=>{
        if (err)
            return callback(err);
        callback(null,res);
    });
}

function downloadDeviceFirmware(deviceInformation,callback) {
    var path = `${hostName}/userDevice/get_firmware`;
    console.log(path);
    fetchAL.fetchAL(path,fetchAL.METHODS.POST,deviceInformation,(err,res)=>{
        if (err)
            return callback(err);
        callback(null,res);
    });
}

function addDeviceType(deviceType, callback) {
    fetchAL.fetchAL(`${hostName}/userDevice/devices`,fetchAL.METHODS.POST,deviceType,(err,res)=>{
        if (err)
            return callback(err);
        callback(null,res);
    });
}

function deleteDeviceType(deviceType, callback) {
    fetchAL.fetchAL(`${hostName}/userDevice/delete_device`,fetchAL.METHODS.POST,deviceType,(err,res)=>{
        if (err)
            return callback(err);
        callback(null,res);
    });
}

module.exports = {
    DeviceCheck,
    DeviceFirmware,
    DeviceFlash,
    DeviceUpdate,
    Ping,
    flashFiles,
    setHostName,
    SmallPing, 
    userLogin,
    userRegister,
    userCheck,
    userLogout,
    userSettings,
    userAddRole,
    userDeleteRole,
    userAddRoles2User,
    firmwareUpload,
    firmwareSetLatest,
    userEditRole,
    downloadDeviceFirmware,
    addDeviceType,
    deleteDeviceType
}